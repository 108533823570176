.header {
    display: flex;
    align-items: center;
    background-color: #52c3ff;
    padding: 10px 20px;
    font-family: Arial, sans-serif;
  }
  
  .logo {
    flex: 0.8;
    color: black;
    font-weight: bold;
  }
  
  .menu {
    display: flex;
    gap: 5px;
  }
  
  .menu-button {
    background-color: #5ae1e2;
    border: none;
    color: black;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 15px;
    border-radius: 5px;
    transition: all 0.3s ease;
    height: 30px;
    align-self: flex-end; /* Align non-active buttons to the bottom */
  }
  
  .menu-button.active {
    margin-left: 350px;
    background-color: white;
    color: black;
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 18px;
    height: 40px;
    align-self: center; /* Center the active button */
  }
  
  .menu-button:hover {
    background-color: #008fcc;
    color: white;
  }
  